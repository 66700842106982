import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import { createNewNotification } from "../../../actions/notificationsActions";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
  inputWrapper: {
    paddingX: "0.25rem",
  },
};

const ProjectsCreateDialog = (props) => {
  const [text, setText] = useState("");

  const dispatch = useDispatch();

  const handleClose = (e) => {
    props.onClose();
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = (e) => {
    handleClose();
    dispatch(
      createNewNotification({
        notification: {
          text,
          users: props.selectedInvestors,
        },
        callback: () => {},
      })
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: "640px", height: "320px", borderRadius: "1em" },
      }}
    >
      <DialogTitle>Escribir texto:</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={{ px: 1 }}>
            <TextField
              variant="outlined"
              style={{ backgroundColor: "#EDEDED" }}
              rows={4}
              label="texto"
              name="name"
              value={text}
              onChange={handleChange}
              multiline
              inputProps={{ disableUnderline: true, style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16, color: "#212427" } }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleSubmit}>
          Enviar
        </Button>
        <Button variant="text" onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectsCreateDialog;
