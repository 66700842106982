// import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AttachFileOutlined } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../../../slices/alertsSlice";
const { REACT_APP_HOST_URL } = process.env;

function ReportItemActions({ investorItem, localReport, distribution }) {
  console.log({ investorItem, distribution });
  const dispatch = useDispatch();

  const handleAppLink = () => {
    window.open(
      `${REACT_APP_HOST_URL}/images/reports/${
        localReport?.path ?? "undefined"
      }`,
      "_blank"
    );
  };

  const handleClip = () => {
    navigator.clipboard.writeText(
      `https://inversionista.ide.fund/dashboard/proyectos/${investorItem._id}/reportes/${localReport._id}`
    );
    dispatch(
      showAlert({ message: "Copiado al portapapeles", type: "success" })
    );
  };

  //   const handleSendEmail = () => {
  //     const today = new Date(Date.now());
  //     const body = `Estimado Inversionista ${
  //       investorItem?.associateRef?.name ?? ""
  //     }:\n\n
  // Te informamos que, con fecha ${today.getDay()}/${today.getMonth()}/${today.getFullYear()}, realizamos la distribución de rendimientos correspondiente al periodo "${
  //       distribution?.period ?? "periodo"
  //     }" por tu participación en la Inversión en el Proyecto de ${
  //       investorItem?.projectRef?.name ?? "*"
  //     }, equivalente al 7% anual de retorno sobre tu capital.\n\n
  // Adjunto encontrarás tu comprobante de transferencia. Asimismo, puedes encontrar el reporte detallado de los rendimientos distribuidos aquí: https://inversionista.ide.fund/dashboard/proyectos/${
  //       investorItem._id
  //     }/reportes/${
  //       localReport._id
  //     } o ingresando a nuestra pagina de inversionistas https://inversionista.ide.fund/ \n\n
  // Usuario: ${investorItem?.associateRef?.user ?? ""}\n\n
  // Recuerda que tu contraseña se conforma por tu nombre de usuario, seguido de los números 1234.\n\n
  // En lo sucesivo continuarás recibiendo tus rendimientos de manera trimestral los días 25 de octubre, enero, abril y julio respectivamente.\n\n
  // Estamos a tus órdenes y quedamos pendientes de cualquier duda o comentario al respecto.\n\n
  // Saludos y nuevamente gracias por tu confianza.\n\n
  // Atentamente,\n\n
  // IDE IDEAS EN DESARROLLO\n\n
  //     `;
  //     const encodeBody = encodeURI(body);
  //     window.location = `mailto:gzt-95@hotmail.com?subject=${"Reporte"}&body=${encodeBody}`;
  //   };

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={4} sm={3} lg={2}>
        <Tooltip title="Abrir reporte">
          <Button onClick={handleAppLink} fullWidth size={"small"} color="info">
            <AttachFileOutlined></AttachFileOutlined>
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={4} sm={3} lg={2}>
        <Tooltip title="Link de reporte">
          <Button
            fullWidth
            onClick={handleClip}
            size={"small"}
            color="secondary"
          >
            <ContentCopyIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ReportItemActions;
