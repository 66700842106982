import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AttachFile, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
} from "@mui/material";

import {
  createNewReport,
  updateCurrentReport,
  updateCurrentReportWithFile,
} from "../../../../actions/reportsActions";
import ReportItem from "../../../../components/items/ReportItem";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
    width: {
      md: "60vw",
    },
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const ProjectDetailsFinanceInfo = (props) => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports.list);
  const params = useParams();
  const [file, setFile] = useState(null);
  const [editId, setEditId] = useState(null);
  const [report, setReport] = useState({
    name: "",
    period: "",
    date: new Date().toISOString().slice(0, 10),
    type: "financiero",
  });

  const handleChange = (e) =>
    setReport({ ...report, [e.target.name]: e.target.value });

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleReset = () => {
    setReport({
      name: "",
      period: "",
      date: new Date().toISOString().slice(0, 10),
      type: "financiero",
    });
    setFile(null);
    setEditId(null);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    props.onClose();
    handleReset();
  };

  const handleSubmit = (e) => {
    dispatch(
      createNewReport({
        report: { ...report, projectRef: params.id },
        file,
        query: { projectRef: params.id },
        callback: handleClose,
      })
    );
  };

  const handleEdit = (selectedReport) => {
    console.log("handleEdit");
    console.log(selectedReport);
    setReport({
      ...selectedReport,
      date: new Date(selectedReport.date).toISOString().slice(0, 10),
    });
    setEditId(selectedReport._id);
    setFile(null);
  };

  const handleSubmitEdit = () => {
    dispatch(
      updateCurrentReport({
        id: report._id,
        report: { ...report, projectRef: params.id },
        query: { projectRef: params.id },
        callback: handleClose,
      })
    );
  };

  const handleSubmitEditWithFile = () => {
    dispatch(
      updateCurrentReportWithFile({
        id: report._id,
        report: { ...report, projectRef: params.id },
        file,
        query: { projectRef: params.id },
        callback: handleClose,
      })
    );
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Reportes financieros</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ px: "1rem" }}>
            <Typography variant="h6">Nuevo reporte</Typography>
            <TextField
              label="Nombre"
              name="name"
              value={report.name}
              onChange={handleChange}
            />
            <TextField
              label="Periodo"
              name="period"
              value={report.period}
              onChange={handleChange}
            />
            <TextField
              type="date"
              label="Fecha"
              name="date"
              value={report.date}
              onChange={handleChange}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button variant="text" component="label">
                <input hidden type="file" onChange={handleFileChange} />
                <AttachFile />
              </Button>
              <Typography variant="caption">
                {file
                  ? file.name
                  : editId != null
                  ? "Cambiar documento"
                  : "Seleccionar archivo"}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Button
                disabled={
                  !report.name ||
                  !report.period ||
                  !report.date ||
                  !(file || editId)
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (editId == null) {
                    handleSubmit();
                  } else {
                    if (file == null) {
                      handleSubmitEdit();
                    } else {
                      handleSubmitEditWithFile();
                    }
                  }
                }}
              >
                {editId != null ? "Editar" : "Guardar"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: "1rem" }}>
            <Typography variant="h6">Reportes financieros</Typography>
            {reports
              .filter((report) => report.type === "financiero")
              .map((report) => (
                <ReportItem
                  key={report._id}
                  {...report}
                  report={report}
                  filter="financiero"
                  reset={handleReset}
                  onClose={handleClose}
                  handleEditReport={handleEdit}
                />
              ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetailsFinanceInfo;
